import React, { useRef } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    content,
    imgBox,
    infoBox,
    title,
    subtitle,
    eanBox,
    shortDescriptionBox,
    linkButton,
    familyBox,
} from './product-content.module.scss';
import { IProduct } from '../../models/product.model';
import { useTranslation } from '../../hooks/use-translation';
import { config } from '../../config';

import Title from '../atoms/title';
import Button from '../atoms/button';
import ImageGallery from '../molecules/image-gallery';
import ProductDropdown from '../molecules/product-dropdown';
import Markdown from '../hoc/markdown';

const { translationKeys } = config;

interface IProductContentProps {
    className?: string;
    product: IProduct;
    familyProducts: IProduct[];
}

const ProductContent: React.FC<IProductContentProps> = ({
    className = '',
    product,
    familyProducts,
}) => {
    const { t } = useI18next();
    const infoBoxRef = useRef<HTMLDivElement>(null);
    const { media, ean } = product;
    const { name, shortName, shortDescription } = useTranslation(product, translationKeys.product);

    return (
        <div className={`${content} ${className}`}>
            <ImageGallery media={media} className={imgBox} />
            <div className={infoBox} ref={infoBoxRef}>
                <Title Tag="h1" className={title} size="medium" containerRef={infoBoxRef}>
                    {name}
                </Title>
                <h2 className={subtitle}>{shortName}</h2>
                <p className={eanBox}>EAN: {ean.join(', ')}</p>
                <Markdown styleType="common" className={shortDescriptionBox}>
                    {shortDescription}
                </Markdown>
                {familyProducts.length > 0 && (
                    <ProductDropdown
                        products={familyProducts}
                        className={familyBox}
                        selectText={t('product.content.dropdown.select')}
                    />
                )}
                <Button to="/contact" className={linkButton} as="link">
                    {t('product.content.contact')}
                </Button>
            </div>
        </div>
    );
};

export default ProductContent;
