import React, { useState } from 'react';
import { getImage } from 'gatsby-plugin-image';

import { ratio, slider, itemButton } from './image-gallery.module.scss';
import { IMedia } from '../../models/media.model';
import { getAllMediaWithRelations } from '../../utils/get-relation';
import { config } from '../../config';

import RatioImage from '../atoms/ratio-image';
import Slider from '../hoc/slider';

const { relations } = config;

const sliderSettings = {
    arrows: false,
    autoplay: false,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    infinite: false,
};

interface IGalleryProps {
    className?: string;
    media: IMedia[];
    itemRelations?: number[];
}

const ImageGallery: React.FC<IGalleryProps> = ({
    className = '',
    media,
    itemRelations = [relations.mainImage, relations.galleryImage],
}) => {
    const items = getAllMediaWithRelations(media, itemRelations);
    const validItems = items
        .filter((item) => item.remoteImage)
        .sort((a, b) => a.sequence - b.sequence);

    const [activeItem, setActiveItem] = useState<IMedia | undefined>(validItems[0]);
    const [isItemButtonsDisabled, setIsItemButtonsDisabled] = useState<boolean>(false);

    const handleImageChange = (fileId: number): void => {
        if (isItemButtonsDisabled) return;
        setActiveItem(validItems.find((item) => item.fileId === fileId));
    };

    return (
        <div className={className}>
            <RatioImage
                image={activeItem?.remoteImage && getImage(activeItem.remoteImage)}
                alt={activeItem?.alt || ''}
                ratioClass={ratio}
                objectFit="contain"
            />
            {validItems.length > 1 && (
                <Slider
                    className={slider}
                    slidesCount={validItems.length}
                    settings={sliderSettings}
                    isSmall={true}
                    onChange={
                        sliderSettings.slidesToShow < validItems.length
                            ? setIsItemButtonsDisabled
                            : undefined
                    }
                >
                    {validItems.map((item) => {
                        return (
                            <button
                                key={`item-button-${item.fileId}`}
                                onClick={() => handleImageChange(item.fileId)}
                                className={itemButton}
                            >
                                <RatioImage
                                    image={item.remoteImage && getImage(item.remoteImage)}
                                    alt={item.alt || ''}
                                    ratioClass={ratio}
                                    objectFit="contain"
                                />
                            </button>
                        );
                    })}
                </Slider>
            )}
        </div>
    );
};

export default ImageGallery;
