import React, { useState } from 'react';
import { SwiperOptions } from 'swiper';

import {
    container,
    header,
    sliderContainer,
    slider,
    cardBox,
    card,
} from './product-slider.module.scss';
import { IProduct } from '../../models/product.model';

import Title, { ITitle } from '../atoms/title';
import Button from '../atoms/button';
import ProductCard from '../molecules/product-card';
import NewSlider from '../hoc/new-slider';

interface IProductSliderProps {
    SlideComponent?: React.ElementType;
    className?: string;
    titleClassName?: string;
    title?: string;
    titleData?: ITitle;
    hasButton?: boolean;
    buttonText?: string;
    buttonUrl?: string;
    products: IProduct[];
    sliderSettings?: SwiperOptions;
}

const ProductSlider: React.FC<IProductSliderProps> = ({
    SlideComponent = ProductCard,
    className = '',
    title = '',
    titleData,
    titleClassName = '',
    buttonText = '',
    buttonUrl = '',
    products,
    sliderSettings,
}) => {
    const [isCardDisabled, setIsCardDisabled] = useState<boolean>(false);

    return (
        <div className={`${container} ${className}`}>
            {title && (
                <Title className={`${header} ${titleClassName}`} {...titleData}>
                    {title}
                </Title>
            )}
            <div className={sliderContainer}>
                <NewSlider
                    settings={sliderSettings || defaultSliderSettings}
                    className={slider}
                    slidesCount={products.length}
                    onChange={setIsCardDisabled}
                >
                    {products.map((product, index) => {
                        return (
                            <div className={cardBox} key={`product-card-${index}`}>
                                <SlideComponent
                                    className={card}
                                    product={product}
                                    isDisabled={isCardDisabled}
                                />
                            </div>
                        );
                    })}
                </NewSlider>
            </div>
            {buttonUrl && buttonText && (
                <Button to={buttonUrl} as="link">
                    {buttonText}
                </Button>
            )}
        </div>
    );
};

const defaultSliderSettings = {
    navigation: false,
    autoplay: { delay: 5000, disableOnInteraction: false },
    pagination: false,
    speed: 500,
    slidesPerView: 1,
    loop: true,
    breakpoints: {
        700: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
};

export default ProductSlider;
