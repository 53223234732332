import React, { useRef, useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    open,
    chevron,
    item,
    toggleButton,
    dropdown,
} from './product-dropdown.module.scss';
import { IProduct } from '../../models/product.model';
import ChevronIcon from '../../assets/images/svg/chevron.svg';

import SearchItem from '../atoms/search-item';
import Dropdown from '../hoc/dropdown';

interface IProductDropdownProps {
    className?: string;
    products: IProduct[];
    selectText?: string;
}

const ProductDropdown: React.FC<IProductDropdownProps> = ({
    className = '',
    products,
    selectText,
}) => {
    const { t } = useI18next();
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleCloseDropdown = () => {
        setIsDropdownOpen(false);
    };

    return (
        <div className={`${container} ${className} ${isDropdownOpen ? open : ''}`}>
            <button ref={buttonRef} className={toggleButton} onClick={toggleDropdown}>
                {selectText || t('product.dropdown.default')} <ChevronIcon className={chevron} />
            </button>
            <Dropdown
                isOpen={isDropdownOpen}
                closeFunction={handleCloseDropdown}
                className={dropdown}
                buttonRef={buttonRef}
            >
                {products.map((product) => {
                    return (
                        <SearchItem
                            key={product.productId}
                            item={product}
                            titleKey="name"
                            subtitleKey="shortName"
                            className={item}
                            hasRemoteImage={true}
                            imageObjectFit="contain"
                        />
                    );
                })}
            </Dropdown>
        </div>
    );
};

export default ProductDropdown;
