import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { getImage } from 'gatsby-plugin-image';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import { header, title, expandButton, ratio, imageRow } from './product-description.module.scss';
import { config } from '../../config';
import { IProduct } from '../../models/product.model';
import { IMedia } from '../../models/media.model';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useTranslation } from '../../hooks/use-translation';
import { getAllMediaWithRelations } from '../../utils/get-relation';

import Title from '../atoms/title';
import Button from '../atoms/button';
import Markdown from '../hoc/markdown';
import RatioImage from '../atoms/ratio-image';

const { translationKeys, relations } = config;

interface IProductionDescriptionImageData {
    image: IGatsbyImageData | undefined;
    alt: string;
}

interface IProductDescriptionProps {
    className?: string;
    product: IProduct;
}

const ProductDescription: React.FC<IProductDescriptionProps> = ({ className = '', product }) => {
    const { t } = useI18next();
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const [imagesData] = useState<IProductionDescriptionImageData[]>(getImagesData(product.media));
    const { description } = useTranslation(product, translationKeys.product);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return description ? (
        <div className={className}>
            <div className={header}>
                <Title className={title} size="medium">
                    {t('product.content.description')}
                </Title>
                <Button
                    className={expandButton}
                    onClick={handleToggle}
                    isRound={true}
                    aria-label={t(isExpanded ? 'product.content.hide' : 'product.content.show')}
                >
                    {isExpanded ? '-' : '+'}
                </Button>
            </div>
            <AnimateHeight height={isExpanded ? 'auto' : 0}>
                {imagesData.length > 0 && (
                    <div className={imageRow}>
                        {imagesData.map((imageData, index) => {
                            return (
                                <RatioImage
                                    key={`secondary-image-${index}`}
                                    image={imageData.image}
                                    alt={imageData.alt}
                                    ratioClass={ratio}
                                />
                            );
                        })}
                    </div>
                )}
                <Markdown styleType="common">{description}</Markdown>
            </AnimateHeight>
        </div>
    ) : null;
};

function getImagesData(mediaItems: IMedia[]): IProductionDescriptionImageData[] {
    const allSecondaryMediaItems = getAllMediaWithRelations(mediaItems, [relations.secondaryImage]);
    return allSecondaryMediaItems.slice(0, 2).map((mediaItem) => {
        return {
            image: mediaItem.remoteImage && getImage(mediaItem.remoteImage),
            alt: mediaItem.alt || '',
        };
    });
}

export default ProductDescription;
