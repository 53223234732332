import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { layout, content, descriptionBox, related } from './product.module.scss';
import { config } from '../config';
import { ISitePage } from '../models/site-page.model';
import { IProduct } from '../models/product.model';
import { useTranslation } from '../hooks/use-translation';
import { getMediaWithRelation } from '../utils/get-relation';
import { getBreadcrumbItem, getBreadcrumbs } from '../utils/get-breadcrumbs';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import ProductContent from '../components/organisms/product-content';
import ProductDescription from '../components/organisms/product-description';
import ProductSlider from '../components/organisms/product-slider';

const { relations, translationKeys } = config;

interface IProductProps {
    readonly data: {
        product: IProduct;
        categoriesSitePage: ISitePage;
        allRelatedProducts: { edges: { node: IProduct }[] };
        allFamilyProducts: { edges: { node: IProduct }[] };
    };
}

const Product: React.FC<IProductProps> = ({ data }) => {
    const { t } = useI18next();
    const { product, categoriesSitePage, allFamilyProducts, allRelatedProducts } = data;
    const { name, shortName, shortDescription } = useTranslation(product, translationKeys.product);
    const { media, breadcrumbs } = product;

    const relatedProducts = allRelatedProducts.edges.map(({ node }) => node);
    const familyProducts = allFamilyProducts.edges.map(({ node }) => node);

    return (
        <>
            <SEO
                title={name}
                description={`${shortName}. ${shortDescription}`}
                image={getMediaWithRelation(media, relations.mainImage)?.url}
            />
            <MainLayout
                className={layout}
                gridSize="small"
                breadcrumbsConfig={{
                    items: [
                        getBreadcrumbItem(categoriesSitePage.context),
                        ...getBreadcrumbs(breadcrumbs[0], 'name'),
                        getBreadcrumbItem(product, 'name'),
                    ],
                }}
            >
                <ProductContent
                    className={content}
                    product={product}
                    familyProducts={familyProducts}
                />
                <ProductDescription className={descriptionBox} product={product} />
                {relatedProducts.length > 0 && (
                    <ProductSlider
                        className={related}
                        products={relatedProducts}
                        title={t('related.products')}
                    />
                )}
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query(
        $language: String!
        $productId: Int!
        $relatedProductsIds: [Int!]
        $familyProductsIds: [Int!]
    ) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        product(productId: { eq: $productId }) {
            ...productFields
        }

        categoriesSitePage: sitePage(context: { pageKey: { eq: "categories" } }) {
            ...sitePageFields
        }

        allRelatedProducts: allProduct(filter: { productId: { in: $relatedProductsIds } }) {
            edges {
                node {
                    ...productCardFields
                }
            }
        }

        allFamilyProducts: allProduct(filter: { productId: { in: $familyProductsIds } }) {
            edges {
                node {
                    ...productCardFields
                }
            }
        }
    }
`;

export default Product;
